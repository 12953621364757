@import "~stream-chat-react/dist/scss/v2/index.scss";

$borderRadius: 0px;

#__next {
	.str-chat {
		&__theme-dark {
			--str-chat__font-family: var(--main-font);
			--str-chat__background-color: black;
			--str-chat__avatar-background-color: black;
			--str-chat__own-message-bubble-background-color: #141414;
			--str-chat__message-bubble-background-color: transparent;
			--str-chat__quoted-message-bubble-background-color: transparent;
			--str-chat__voice-recording-attachment-widget-background-color: transparent;
			--str-chat__audio-attachment-controls-button-background-color: transparent;
			--str-chat__message-input-background-color: transparent;
			--str-chat__disabled-color: rgba(255, 255, 255, 0.6);
			--str-chat__text-low-emphasis-color: #7a7a7a;
			--str-chat__primary-color: #eb0f00;
			--str-chat__secondary-background-color: #141414;
			--str-chat__secondary-surface-color: #212121;
			--str-chat__message-actions-box-border-block-start: 1px solid var(--onSurface, #e9e9e9);
			--str-chat__message-actions-box-border-block-end: 1px solid var(--onSurface, #e9e9e9);
			--str-chat__message-actions-box-border-inline-start: 1px solid var(--onSurface, #e9e9e9);
			--str-chat__message-actions-box-border-inline-end: 1px solid var(--onSurface, #e9e9e9);
			--str-chat__tertiary-surface-color: #141414;
			--str-chat__audio-attachment-controls-button-color: #7a7a7a;

			// these six styles control all other border radii except for FAB
			--str-chat__border-radius-lg: #{$borderRadius};
			--str-chat__border-radius-md: #{$borderRadius};
			--str-chat__border-radius-sm: #{$borderRadius};
			--str-chat__border-radius-xs: #{$borderRadius};
			--str-chat__border-radius-circle: #{$borderRadius};
			--str-chat__message-notification-border-radius: #{$borderRadius};
		}
		&__channel-preview {
			font-family: var(--main-font);
			&-messenger {
				&--left {
					margin-right: 8px;
				}
				&--last-message {
					font-size: 12px;
					color: #7a7a7a;
				}
				&--name {
					font-weight: 700;
				}
			}
			&-unread-badge {
				background-color: var(--scruff-red);
				border-radius: 8px;
				display: inline-flex;
				padding: 2px 6px 1px 6px;
				align-items: center;
				gap: 10px;
				justify-content: center;
				min-width: 1rem;
				max-height: 1rem;
			}
		}
		&__list {
			padding-top: 108px;
		}
		&__modal {
			&--open {
				& .str-chat-react {
					&__modal__inner {
						width: 90%;
					}
				}
			}
		}
		&__message--me .str-chat__message-text {
			padding: 0;
		}
		&__quoted-message-preview {
			border-left: 1px solid var(--onSurface, #e9e9e9);
		}
		&__quoted-message-preview.mine {
			border-left: none;
			border-right: 1px solid var(--onSurface, #e9e9e9);
		}
		&__message .str-chat__message-inner .str-chat__message-bubble .str-chat__message-text,
		.str-chat__quoted-message-preview .str-chat__message-inner .str-chat__message-bubble .str-chat__message-text {
			padding: var(--str-chat__spacing-2) 0;
		}
		&__message--me .str-chat__message-inner .str-chat__message-bubble .str-chat__message-text {
			padding: var(--str-chat__spacing-2) var(--str-chat__spacing-4);
		}
		&__message .str-chat__quoted-message-bubble {
			padding: var(--str-chat__spacing-2) 0 var(--str-chat__spacing-2) var(--str-chat__spacing-4);
		}
		&__message--me .str-chat__quoted-message-bubble {
			padding: var(--str-chat__spacing-2) var(--str-chat__spacing-4) var(--str-chat__spacing-2) 0;
		}

		&__message.str-chat__message--other,
		.str-chat__message.str-chat__quoted-message-preview,
		.str-chat__quoted-message-preview.str-chat__message--other,
		.str-chat__quoted-message-preview.str-chat__quoted-message-preview {
			column-gap: 0px;
		}
		&__date-separator-line {
			background: none;
		}
		&__date-separator-date {
			border: 1px solid var(--Neutrals-White-15, rgba(255, 255, 255, 0.15));
			padding: 1px 8px;
			color: var(--light-white, #fff);
			text-align: center;
			font-size: 12px;
			line-height: normal;
		}
		&__attachment-list .str-chat__message-attachment__voice-recording-widget {
			.str-chat__wave-progress-bar__amplitude-bar,
			.str-chat__wave-progress-bar__progress-indicator {
				border-radius: 2px;
			}
			.str-chat__wave-progress-bar__progress-indicator {
				border-radius: 2px;
				border: none;
				width: var(--str-chat__voice-recording-amplitude-bar-width);
			}
		}
		&__message-inner .str-chat__message-options {
			display: flex;
			opacity: 0;
		}
		&__ul:not(.str-chat__message-options-in-bubble)
			.str-chat__li:hover:not(:has(.str-chat__reaction-list:hover, .str-chat__modal--open))
			.str-chat__message-options,
		.str-chat__ul:not(.str-chat__message-options-in-bubble)
			.str-chat__li:focus-within:not(:has(.str-chat__reaction-list:focus-within, .str-chat__modal--open))
			.str-chat__message-options,
		.str-chat__virtual-list:not(.str-chat__message-options-in-bubble)
			.str-chat__li:hover:not(:has(.str-chat__reaction-list:hover, .str-chat__modal--open))
			.str-chat__message-options,
		.str-chat__virtual-list:not(.str-chat__message-options-in-bubble)
			.str-chat__li:focus-within:not(:has(.str-chat__reaction-list:focus-within, .str-chat__modal--open))
			.str-chat__message-options {
			opacity: 1;
		}

		// Message input area
		&__message-input {
			flex: 1 0 auto;
			// 6px left padding to account for left "padding" on file-input-container
			padding: 10px 12px 10px 6px;
			min-height: 65px;
		}
		&__message-input-inner {
			gap: 8px;

			> button {
				flex: 0 0 auto;
			}

			> button[disabled] {
				display: none;
			}
		}
		&__message-textarea-container {
			border: 1px solid var(--Neutrals-White-25, rgba(255, 255, 255, 0.25));
			min-height: 44px;
		}
		&__file-input-container {
			flex: 0 0 auto;
			height: 44px;
			width: 36px;
			margin-right: -6px;
			color: var(--Primary);
		}
		&__message-input .str-chat__file-input-container svg path {
			fill: currentColor;
		}
	}
}
