html,
body,
#__next {
	height: 100%;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	overscroll-behavior: none;
}

html {
	min-height: 100%;
	padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
	background-color: #000000;
	--scruff-red: #fa0002;
	--heading-color: var(--BKDRProductFadedPink);
	--safe-top: env(safe-area-inset-top, 0);
	--safe-bottom: env(safe-area-inset-bottom, 0);
}

html body {
	background-color: #000000;
}

#__next {
	transform: scale(1);
	background-color: var(--Surface, black);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	position: relative;
	overflow: auto;
}

.sr-only {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}
